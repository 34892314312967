import Head from "next/head";
import Image from "next/image";
import Link from "next/link";
import { useState, useEffect, useRef } from "react";
import * as Sentry from "@sentry/nextjs";
import { useAppContext } from "contexts/AppContext";
import { useRouter } from "next/router";
import {
    getVideosByContentTypeAndCategory,
    getVideosByContentCategories,
    getAllVideos,
    getDynamicBannerHomepage,
} from "../api";
import { track, scrollTo, cross, useFirstRender } from "utils";
import { E_FILTER_CLEARED } from "utils/constants";
import { motion, AnimatePresence } from "framer-motion";
import SwiperCore, { Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Nav from "@/components/modules/Nav/Nav";
import XoMedia from "@/components/modules/XoMedia/XoMedia";
import OldHomepage from "./old-homepage";
import FullScreenLoader from "@/components/modules/FullScreenLoader/FullScreenLoader";
import styles from "../styles/Watch.module.scss";
import styles2 from "../styles/WatchHomepage.module.scss";


SwiperCore.use([Pagination, Navigation]);

const Home = () => {
    return <OldHomepage />;
};

Home.Nav = <Nav dark={true} />;


export default Home;